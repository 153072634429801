<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <form @submit.prevent="save" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Metric - Set new target</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <LoadingMessage v-if="isLoading"></LoadingMessage>

          <div v-if="metricObj" class="form-floating">
            <input
              v-model.number="newValue"
              ref="val"
              id="val"
              type="number"
              class="form-control"
              placeholder="0.000"
              min="0"
              step="0.001"
              pattern="^\d*(\.\d{0,3})?$"
            >
            <label for="val">
              New target ({{ metricObj.unit }})<sup class="text-danger">*</sup>
            </label>
          </div>

          <ErrorMessage v-if="error" :error="error" class="m-0 mt-3"></ErrorMessage>
        </div>
        <div v-if="metricObj" class="modal-footer">
          <SubmitButton
            :text="'Save'"
            :textBusy="'Please wait...'"
            :isLoading="isSaving"
            :disabled="isSaving || isSubmitDisabled"
            class="btn btn-primary"
          ></SubmitButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { httpGet, httpPost } from '@/core/http';

export default {
  name: 'MetricNewTargetModal',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    metric: {
      type: Object,
      default: null,
    },
    metricId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSubmitDisabled() {
      const { newValue } = this;
      return newValue === '' || newValue <= 0;
    },
  },
  methods: {
    close(forced = false) {
      if ((this.isLoading || this.isSaving) && forced !== true) return;
      this.$emit('close');
    },
    focus() {
      this.$nextTick(() => this.$refs.val.focus());
    },
    async fetchMetric() {
      this.isLoading = true;
      this.error = null;
      try {
        const { userId, metricId } = this;
        const res = await httpGet(`/metric/${metricId}/user/${userId}`);
        this.metricObj = res.data;
        if (this.metricObj) {
          this.focus();
        }
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      this.isSaving = true;
      this.error = null;
      try {
        const { userId, metricId, newValue } = this;
        const payload = {
          userId,
          metricId,
          target: newValue,
        };
        await httpPost(`/metric/${metricId}/user/${userId}`, payload);
        this.$emit('update', newValue);
        this.close(true);
      } catch (err) {
        this.error = err;
      } finally {
        this.isSaving = false;
      }
    },
  },
  data() {
    return {
      isLoading: null,
      isSaving: false,
      error: null,
      newValue: '',
      metricObj: null,
    };
  },
  mounted() {
    if (this.metric) {
      this.metricObj = this.metric;
      this.focus();
    } else {
      this.fetchMetric();
    }
  },
};
</script>
